<template>
    <div>
        <div v-if="blockInfo._id">
            <div class="infoContainer">
                <div class="row1">
                    <div>#{{blockInfo.number}}</div>
                    <div>区块高度</div>
                </div>
                <div class="row2">
                    <div>创建时间：{{timestamp(blockInfo.createTimestamp)}}</div>
                    <div v-if="blockInfo.sealerData">打包节点：{{blockInfo.sealerData.nodeName}}</div>
                    <div>记录数量: {{transactionList.length}}</div>
                </div>
            </div>
            <el-table :data="transactionList" style="width: 100%;margin-top:24px" stripe header-row-class-name="labelClassName">
                <el-table-column label="序号" width="50" align="center">
                    <template slot-scope="scope">{{scope.$index+1}}</template>
                </el-table-column>
                <el-table-column label="交易Hash" width="270" #default="{row}" @click="$router.push({name:'info'})">
                    <div type="text" style="color:#1ECDA4;cursor: pointer;" @click="queryHash(row.transactionHash)">{{row.transactionHash}}</div>
                </el-table-column> 
                <el-table-column prop="createdAt" label="创建时间" width="180" #default="{row}">
                    {{timestamp(blockInfo.createTimestamp)}}
                </el-table-column>
                <el-table-column prop="dataType" label="数据类型" #default="{row}">
                    {{dataType(row.dataType)}}
                </el-table-column>
                <el-table-column prop="recordType" label="记录类型"></el-table-column>
                <el-table-column prop="agencyName" label="上链企业" width="200">
                </el-table-column>
                <el-table-column label="数字证书" align="center" #default="{row}">
                    <el-button icon="el-icon-view" type="text" style="color:#1ECDA4" @click="checkZs(row)">查看</el-button>
                </el-table-column>
            </el-table>
            <el-dialog :visible.sync="dialogVisible" width="558px" @close="dialogVisible=false">
                <el-row justify="center" type="flex" style="flex-direction: column;" align="center">
                    <el-image :src="zsBase64" />
                    <el-button style="margin-top:20px" type="primary" @click="savePicture(zsBase64)">下载</el-button>
                </el-row>
            </el-dialog>
        </div>
        <div v-else style="text-align: center;color: #c9c9c9;">暂无数据</div>
    </div>
</template>


<script>
    import axiosCB from '@/axios/indexCB'
    import { timestamp, zs, savePicture, isCAQS } from '@/comm/ut'
    export default {
        name: "heightBlock",
        props: {},
        data() {
            return {
                zsBase64: "",
                dialogVisible: false,
                blockInfo: {},
                transactionList: [], //区块内记录的交易数量
            }
        },
        async created() {
            this.heightBlock(this.$route.query.value)
        },
        methods: {
            timestamp,
            savePicture,
            dataType(v) {
                if (v === 0) return 'JSON'
                if (v === 1) return '文本'
                if (v === 2) return '图片'
                if (v === 3) return '视频'
                return v
            },
            queryHash(value) {
                this.$parent.input = value
                this.$parent.search(false)
            },
            async heightBlock(blockNubmer) { //21根据区块高度获取区块数据
                let response = await axiosCB.request({
                    url: `network/${this.$store.state.networkName}/blocks/blockInfo/${blockNubmer}`,
                    method: "GET",
                    config: {
                        errorFalg: true //不显示全局错误消息
                    }
                }).catch(err => {
                    this.$message.warning("查无数据")
                    return
                })

                let { blockInfo = null, transactionList = [] } = response.data
                let res = await axiosCB.request({
                    url: `/network/${this.$store.state.networkName}/blocks/sealer/${blockInfo.blockHash}`,
                    method: "GET"
                })
                blockInfo.sealerData = res.data

                transactionList.forEach((item, index) => {
                    item.agencyName = ""
                    axiosCB.request({
                        url: `/network/${this.$store.state.networkName}/agency/${item.fiscoAccount}`,
                        method: "GET"
                    }).then(res => item.agencyName = res.data.agencyName)
                })
                this.blockInfo = blockInfo
                this.transactionList = transactionList
            },
            async checkZs(row = {}) {
                this.zsBase64 = await zs(
                    row.transactionHash,
                    row.blockNumber,
                    row.agencyName,
                    timestamp(row.createdAt),
                    this.blockInfo.sealerData.nodeName,
                    timestamp(this.blockInfo.createTimestamp),
                    isCAQS(row)
                )
                this.dialogVisible = true
            }
        }
    }
</script>

<style scoped lang="less">
    .infoContainer {
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        background: rgba(30, 205, 164, 0.0400);
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #1ECDA4;
        padding: 24px 80px;
        padding-right: 145px;
        box-sizing: border-box;

        .row1 {
            display: flex;
            align-items: center;

            div {
                &:nth-child(1) {
                    font-size: 24px;
                    font-weight: bold;
                    color: #1ECDA4;
                }

                &:nth-child(2) {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                    margin-left: 8px;
                }
            }
        }

        .row2 {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            margin-top: 8px;
        }
    }

    /deep/ .labelClassName {
        font-size: 14px;
        font-weight: 600;
        color: #333333;


        th {
            background-color: rgba(246, 246, 246, 1) !important;
        }
    }

    /deep/ .el-pagination {
        display: flex;
        justify-content: flex-end;
    }
</style>