<template>
    <div class="container">
        <div class="page">
            <div class="title">查询结果</div>
            <div class="contentContainer">
                <div class="searchBlock">
                    <div class="serchContainer">
                        <el-image style="width:16px; height: 16px;flex-shrink: 0;" :src="require('@/assets/image/search.png')" fit="fill"></el-image>
                        <el-input class="input" placeholder="按区块高度或交易Hash搜索" v-model="input"></el-input>
                        <el-button class="searchBtn" type="primary" @click="search(false)">搜索</el-button>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <HeightBlock v-if="type===1"></HeightBlock>
                    <Hash v-if="type===2"></Hash>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HeightBlock from './heightBlock.vue'
    import Hash from './hash.vue'
    export default {
        components: { HeightBlock, Hash },
        data() {
            return {
                input: this.$route.query.value,
                type: 0, //1:区块高度 2hash
            }
        },
        async mounted() {
            this.search(true)
        },
        methods: {
            async search(mounted = false) {
                let value = this.input.toString()
                if (value !== '0') {
                    if (!value) {
                        this.$message.warning("请输入查询内容")
                        return
                    }
                }
                if (!mounted) {
                    await this.$router.replace({
                        name: "searchResult",
                        query: {
                            value
                        }
                    })
                }
                this.type = 0
                this.$nextTick(() => {
                    if (value.length >= 66) {
                        this.type = 2
                    } else {
                        this.type = 1
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .container {
        height: calc(100vh - 128px);
        background-color: #FDFAFE;
    }

    .title {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        padding-top: 32px;
    }

    .contentContainer {
        margin-top: 8px;
        width: 100%;
        padding: 40px;
        box-sizing: border-box;
        background-color: #FFFFFF;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.0300);
        border-radius: 2px 2px 2px 2px;
    }

    .searchBlock {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .type {
            height: 50px;
            line-height: 50px;
            width: 131px;
            text-align: center;
            background: #FFFFFF;
            box-shadow: 0px 3px 6px 1px rgba(7, 50, 43, 0.1000);
            border-radius: 40px 40px 40px 40px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            position: relative;
            padding-left: 8px;

            &::before {
                content: "";
                position: absolute;
                left: 24px;
                top: 20px;
                background-image: url('../../assets/image/sjx1.png');
                background-size: 100% 100%;
                width: 14px;
                height: 10px;
            }
        }

        .serchContainer {
            margin-left: 8px;
            width: 700px;
            height: 50px;
            background: #FFFFFF;
            box-shadow: 0px 3px 6px 1px rgba(7, 50, 43, 0.1000);
            border-radius: 40px 40px 40px 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            padding-left: 24px;
            box-sizing: border-box;

            .input {
                flex: auto;
                margin: 0 16px;
                font-size: 14px;
                font-weight: 400;

                /deep/ .el-input__inner {
                    border-color: rgba(0, 0, 0, 0) !important;
                }
            }

            .searchBtn {
                flex-shrink: 0;
                height: 40px;
                line-height: 40px;
                padding: 0;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                background: #1ECDA4;
                width: 88px;
                border-radius: 40px 40px 40px 40px;
                border-color: unset;
            }
        }
    }
</style>