<template>
    <div>
        <div v-if="blockInfo.transactionHash">
            <div class="infoContainer">
                <div class="row1">
                    <div>#{{blockInfo.blockNumber}}</div>
                    <div>区块高度</div>
                </div>
                <div class="row2">
                    <div class="item">交易Hash：<span>{{blockInfo.transactionHash}}</span></div>
                </div>
                <div class="row2">
                    <div class="item">创建时间：{{timestamp(blockInfo.createdAt)}}</div>
                    <div class="item">记录类型：{{blockInfo.recordType}}</div>
                    <div class="item" v-if="blockInfo.sealerData">打包节点：{{blockInfo.sealerData.nodeName}}</div>
                </div>
                <div class="row3">
                    <div class="item">上链企业：{{blockInfo.agencyName}}</div>
                    <div class="item">
                        <div>数字证书：</div>
                        <div class="check" @click="checkZs">查看</div>
                    </div>
                    <div class="item"></div>
                </div>
            </div>

            <div class="ttile">记录数据</div>
            <div class="collapseContainer">
                <!-- <el-collapse v-model="activeName" accordion v-if="blockInfo.input && resultCan">
                <el-collapse-item :title="item.title" :name="index" v-for="(item,index) in blockInfo.input.result" :key="index">
                    <div v-if="item.txt">{{item.txt}}</div>
                    <el-image v-if="item.img" style="width: 100%" :src="item.img" fit="contain"></el-image>
                    <videoPlayer v-if="item.video" class="video-player-box" ref="videoPlayer" :options="playerOptions(item.video)" :playsinline="true" customEventName="customstatechangedeventname" />
                </el-collapse-item>
            </el-collapse> -->
                <el-collapse v-model="activeName">
                    <el-collapse-item title="数据" :name="0">
                        {{blockInfo.input.result}}
                    </el-collapse-item>
                </el-collapse>
            </div>
            <el-dialog :visible.sync="dialogVisible" width="558px" @close="dialogVisible=false">
                <el-row justify="center" type="flex" style="flex-direction: column;" align="center">
                    <el-image :src="zsBase64" />
                    <el-button style="margin-top:20px" type="primary" @click="savePicture(zsBase64)">下载</el-button>
                </el-row>
            </el-dialog>
        </div>
        <div v-else style="text-align: center;color: #c9c9c9;">暂无数据</div>
    </div>
</template>

<script>
    import 'video.js/dist/video-js.css'
    import { videoPlayer } from 'vue-video-player'
    import axiosCB from '@/axios/indexCB'
    import { timestamp, zs, savePicture, isCAQS } from '@/comm/ut'
    export default {
        name: "hash",
        components: {
            videoPlayer
        },
        data() {
            return {
                resultCan: false,
                blockInfo: {},
                zsBase64: "",
                dialogVisible: false,
                activeName: 0
            }
        },
        async created() {
            this.getInfo(this.$route.query.value)
        },
        methods: {
            timestamp,
            savePicture,
            async checkZs() {
                this.zsBase64 = await zs(this.blockInfo.transactionHash, this.blockInfo.blockNumber, this.blockInfo.agencyName, timestamp(this.blockInfo.createdAt), this.blockInfo.sealerData.nodeName, timestamp(this.blockInfo.createTimestamp), isCAQS(this.blockInfo))
                this.dialogVisible = true
            },
            playerOptions(video) {
                return {
                    muted: true, // 默认情况下将会消除任何音频。
                    language: 'zh-CN',
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    playbackRates: [0.5, 1.0, 1.5, 2.0],
                    sources: [{
                        type: "video/mp4",
                        src: video
                    }],
                    // poster: "/static/images/author.jpg",
                }
            },
            async getInfo(transactionHash) {
                let response = await axiosCB.request({
                    url: `network/${this.$store.state.networkName}/transactions/content/hash`,
                    params: {
                        transactionHash
                    },
                    method: "GET"
                })
                let blockInfo = response.data
                if (!blockInfo) {
                    this.$message.warning("查无数据")
                    return
                }
                // 用高度查一下 拿到打包时间
                let resTemp = await axiosCB.request({
                    url: `network/${this.$store.state.networkName}/blocks/blockInfo/${blockInfo.blockNumber}`,
                    method: "GET"
                }).catch(e => { console.log(1) })
                blockInfo.createTimestamp = resTemp.data.blockInfo.createTimestamp

                let res = await axiosCB.request({
                    url: `/network/${this.$store.state.networkName}/blocks/sealer/${blockInfo.blockHash}`,
                    method: "GET"
                })
                blockInfo.sealerData = res.data
                if (!blockInfo.input || !blockInfo.input.result || blockInfo.input.result.length == 0) return
                blockInfo.input.result.forEach((item, index) => {
                    // if (typeof item !== "string") {
                    //     this.resultCan = false
                    //     return
                    // }
                    // if (item.indexOf("isCAQS") !== -1) {
                    //     this.resultCan = false
                    //     return
                    // }
                    if (typeof item === 'string' && item.indexOf("{") !== -1 && item.indexOf("}") !== -1) {
                        let obj = JSON.parse(item)
                        blockInfo.input.result[index] = obj
                        if (obj.video || obj.img || obj.title || obj.text) this.resultCan = true
                        return
                    }
                })
                await axiosCB.request({
                    url: `/network/${this.$store.state.networkName}/agency/${blockInfo.fiscoAccount}`,
                    method: "GET"
                }).then(res => {
                    blockInfo.agencyName = res.data.agencyName
                })
                this.blockInfo = blockInfo

            }
        }
    }
</script>

<style scoped lang="less">
    .infoContainer {
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        background: rgba(30, 205, 164, 0.0400);
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #1ECDA4;
        padding: 24px 80px;
        box-sizing: border-box;

        .row1 {
            display: flex;
            align-items: center;

            div {
                &:nth-child(1) {
                    font-size: 24px;
                    font-weight: bold;
                    color: #333333;
                }

                &:nth-child(2) {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                    margin-left: 8px;
                }
            }
        }

        .row2 {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            margin-top: 8px;

            .item {
                flex: 1;
                text-align: left;

                &:nth-child(1) {
                    span {
                        font-size: 18px;
                        font-weight: bold;
                        color: #1ECDA4;
                    }
                }
            }
        }

        .row3 {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            margin-top: 8px;

            .item {
                flex: 1;
                text-align: left;


                &:nth-child(2) {
                    display: flex;
                    align-items: center;

                    .check {
                        cursor: pointer;
                        width: 48px;
                        height: 24px;
                        text-align: center;
                        line-height: 24px;
                        background: #1ECDA4;
                        border-radius: 12px 12px 12px 12px;
                        font-size: 13px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }

    .ttile {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        padding-left: 8px;
        margin-top: 37px;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            left: 0;
            top: 3px;
            width: 4px;
            height: calc(100% - 6px);
            background-color: #16BF98;
        }
    }

    .collapseContainer {
        margin-top: 12px;
        border: 1px solid #DDDDDD;
        border-top: 0;
        border-bottom: 0;

        /deep/ .el-collapse-item__header {
            padding: 14px 24px;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            background: #F6F6F6;

            &.is-active {
                border-bottom: 1px solid #EBEEF5;
            }
        }

        /deep/ .el-collapse-item__content {
            padding: 24px;
            box-sizing: border-box;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
        }
    }

    /deep/ .video-player-box {
        width: 100%
    }
</style>